import React from "react";
import SoilImage from '../../assets/image/SoilTech.svg'
 // Adjust the import path for your image
import "./LeftColumn.css"; // Adjust the import path for CSS

const LeftColumn = ({ isMobile }) => {
  return (
    <div className="left-panel-signup">
      {isMobile ? (
        <div style={{ height: "250px", position: "relative" }}>
          <div className="text-overlay-signup-mobile">
            <div className="text-layout-signup-mobile">
              <img src={SoilImage} alt="Logo" className="logo" />
              <h1 className="text-signup-header">
                DIGITAL FARMING WITH POWER OF AI
              </h1>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="text-overlay-signup">
            <h1 className="text-overlay-signup-header">
              DIGITAL FARMING WITH POWER OF AI
            </h1>
          </div>
          <div className="text-overlay2-signup">
            <p className="text-layout2-text-signup">
              Today, we create innovative solutions to the challenges that
              consumers face in both their everyday lives and events.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftColumn;
