import React from "react";
import './TermsAcceptance.css'

function TermsAcceptance({ isChecked, onChange }) {
  return (
    <div className="terms_signup">
      <input
        className="terms_signup_input"
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <p className="termsText">
        By continuing, you indicate that you read and agreed to the Terms of Use
      </p>
    </div>
  );
}

export default TermsAcceptance;
