import React,{useEffect,useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ChangePassword.css";
import backgroundImg from "../../assets/image/farmers-scheme-istock.svg";
import axios from "axios";
import bcrypt from "bcryptjs";
import { toast, ToastContainer } from "react-toastify";
import SoilImage from '../../assets/image/SoilTech.svg'
import "react-toastify/dist/ReactToastify.css";
import LeftColumn from "../../components/LeftColumn/LeftColumn";
import PasswordValidation from "../../components/RegisterComponent/PasswordValidation";
import FormInput from "../../components/RegisterComponent/FormInput";

function ChangePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const email = location.state?.email;
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    match: false,
  });
  const [showValidationList, setShowValidationList] = useState(true);


  // Listen for window resize to adjust layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validatePassword = (password, confirmPassword) => {
    const validations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      match: password === confirmPassword,
    };
    setPasswordValidation(validations);
    setShowValidationList(!Object.values(validations).every((v) => v));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "password") {
      setNewPassword(value);
      validatePassword(value, confirmPassword); // Validate password with the confirm password
    }
  
    if (name === "confirmpassword") {
      setConfirmPassword(value);
      validatePassword(newPassword, value); // Validate confirm password with the new password
    }
  
    if (name === "otp") {
      setOtp(value); // Set OTP
    }
  };
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!otp || !newPassword || !confirmPassword) {
      toast.error("Please fill out all fields.");
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    try {
      const salt = bcrypt.genSaltSync(10); // Generate salt
      const hashedPassword = bcrypt.hashSync(newPassword, salt);
      const response = await axios.post('https://soiltech.ai/api/login/reset-password', {email, otp, hashedPassword });
      if (response.status === 200) { // Check for 200 status
        //  console.log("entering the function");
        //  console.log("Password reset successful:", response.data.message);
        toast.success(response.data.message);
        // navigate("/",{ replace: true }); // Navigate to login page upon success
        setTimeout(() => {
          navigate("/signin", { replace: true }); // Navigate to login page after a delay
      }, 500);
      }
    } catch (error) {
      toast.error("Failed to reset password. Please try again.");
    }
  };
  return (
    <div className="container">
        
      <LeftColumn isMobile={isMobile} />     
      <div className="right-panel-change">
        { isMobile? (
          <div className="signup-container">
          <h2>Welcome to Soil Tech</h2>
          <p>Getting started is easy</p>
          <form className="ChangePassword-form" onSubmit={handlePasswordReset}>
            <label> Change Password</label>

            <div className="Input-box">
            <FormInput
              label="OTP"
              type="text"
              name="otp"
              onChange={handleInputChange}
              placeholder="Enter the OTP Received"
            />
            <FormInput
              label="Password"
              type="password"
              name="password"
              value={newPassword}
              onChange={handleInputChange}
              placeholder="Enter Password"
            />
            <FormInput
              label="Confirm Password"
              type="password"
              name="confirmpassword"
              value={confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm Password"
            />
            <PasswordValidation
              passwordValidation={passwordValidation}
              showValidationList={showValidationList}
            />
            {/* <input name= "otp" type="text"  value={otp}
            onChange={handleInputChange} placeholder="Enter OTP" required /> */}
            </div>
            {/* <p style={{fontSize:'0.7em',fontStyle:'normal',fontWeight:'500',color: 'var(--Neutral-700, #4B4B4B)',alignContent:'start'}}>Enter the OTP sent on your registered email ID</p> */}
          

            <button type="submit" className="change-submit-btn" >
              SUBMIT
            </button>
          </form>
        
        
      
          <div className="change-prompt-container">
          <p className="login-text">
            Don’t have an account?<a href="/signup" className="login-link">Sign up!</a>
          </p>
            </div>
          
        </div>) : 
      
      (
      <>
        <div className="change-header-container">
          <div className="logo-container">
          <img src={SoilImage} alt="Logo"  className="logo" />
          </div>
          <div className="change-prompt-container">
          <p className="login-text">
            Don’t have an account?<a href="/signup" className="login-link">Sign up!</a>
          </p>
            </div>
          </div>
        <div className="signup-container">
          <h2>Welcome to Soil Tech</h2>
          <p>Getting started is easy</p>
          
        

          <form className="ChangePassword-form" onSubmit={handlePasswordReset}>

            <div className="Input-box">
            <FormInput
              label="OTP"
              type="text"
              name="otp"
              onChange={handleInputChange}
              placeholder="Enter the OTP Received"
            />
            <FormInput
              label="Password"
              type="password"
              name="password"
              value={newPassword}
              onChange={handleInputChange}
              placeholder="Enter Password"
            />
            <FormInput
              label="Confirm Password"
              type="password"
              name="confirmpassword"
              value={confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm Password"
            />
            <PasswordValidation
              passwordValidation={passwordValidation}
              showValidationList={showValidationList}
            />
            </div>
            <button type="submit" className="change-submit-btn" >
              SUBMIT
            </button>
          </form>
        </div></>)}

      </div>
      <ToastContainer/>
    </div>
  );
}

export default ChangePassword;
