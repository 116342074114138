import React from "react";

function PasswordValidation({ passwordValidation, showValidationList }) {
  return (
    showValidationList && (
      <ul className="password-validation">
        <li style={{ color: passwordValidation.length ? "green" : "red" }}>
          At least 8 characters
        </li>
        <li style={{ color: passwordValidation.uppercase ? "green" : "red" }}>
          At least 1 uppercase letter
        </li>
        <li style={{ color: passwordValidation.lowercase ? "green" : "red" }}>
          At least 1 lowercase letter
        </li>
        <li style={{ color: passwordValidation.specialChar ? "green" : "red" }}>
          At least 1 special character
        </li>
        <li style={{ color: passwordValidation.match ? "green" : "red" }}>
          Passwords match
        </li>
      </ul>
    )
  );
}

export default PasswordValidation;
