import React,{useState,useEffect} from "react";
import EndUsercss from "./EndUser.module.css";
import SoilTech from "../../assets/image/SoilTech.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const EndUser = () => {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const changeHandler = (e)=> {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value})
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post("https://soiltech.ai/api/endtoken", formData);
      console.log(response.data);
      setIsSubmitting(false);
      setFormData({});
      toast.success("Test link sent successfully!", { position: "top-center" ,autoClose: 1500});
    } catch (error) {
      console.error("Error submitting form data:", error.response?.data || error.message);
    }
  };



  return (
    <div className={EndUsercss.Container}>
        <div className={EndUsercss.LeftHalf}>
        <div className={EndUsercss.TextOverlay}>
              <div className={EndUsercss.TextOverlayHeader}> DIGITAL FARMING WITH POWER OF AI</div>
           </div>
           <div className={EndUsercss.TextOverlay2}>
              <div className={EndUsercss.TextOverlayPara}>
                 Today, we create innovative solutions to the challenges that
                 consumers face in both their everyday lives and events.
              </div>
           </div>
        </div>
        <div className={EndUsercss.RightHalf}>
         <div className={EndUsercss.Welcome}>
            <img src={SoilTech}  className={EndUsercss.SoilTechLogo}/>  
        </div>
        <div className={EndUsercss.WelcomeText}>Welcome to SoilTech</div>
        <div className={EndUsercss.WelcomeText2}>Submit Your Details</div>
        <form className={EndUsercss.Form} onSubmit={submitHandler} action="/">
          <div className={EndUsercss.InputField}>
            <label htmlFor="fullName">Full Name</label>
            <input
              className=""
              type="text"
              id="name"
              name = "name"
              value={formData.name || ""}
              onChange={changeHandler}
              placeholder="Enter your full name"
            />
          </div>
          <div className={EndUsercss.InputField}>
            <label htmlFor="email">Company Email</label>
            <input
              type="email"
              id="email"
              className=""
              name="email"
              value={formData.email || ""}
              onChange={changeHandler}
              placeholder="Enter your email"
            />
          </div>
          <div className={EndUsercss.InputField}>
            <label htmlFor="contact">Contact Number</label>
            <input
              type="tel"
              id="phoneNumber"
              className=""
              name="phoneNumber"
              value={formData.phoneNumber || ""}
              onChange={changeHandler}
              placeholder="Enter your contact number"
            />
          </div>
          <button type="submit" disabled={isSubmitting} className={EndUsercss.SubmitButton}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      
        </div>
        <ToastContainer/>
    </div>
  )
}

export default EndUser;