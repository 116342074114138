import React from "react";
import './FormInput.css'

function FormInput({ label, type, name, value, onChange, placeholder, error }) {
  return (
    <div className="input-group">
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
}

export default FormInput;
