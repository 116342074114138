import React from 'react';
import { useNavigate } from 'react-router-dom';
import MemberEndUsercss from "./MemberEndUser.module.css";
import MemberPhoto from "../../assets/image/MemberPhoto.svg";
import SoilTech from "../../assets/image/SoilTech.svg";

const MemberEndUser = () => {
    const navigate = useNavigate();


    const handleNavigate1 = () => {
        navigate('/signin'); 
      };
      const handleNavigate2 = () => {
        navigate('/enduser'); 
      };
  return (
    <div className={MemberEndUsercss.Container}>
        <div className={MemberEndUsercss.LeftHalf}>
            <div className={MemberEndUsercss.TextOverlay}>
              <div className={MemberEndUsercss.TextOverlayHeader}> DIGITAL FARMING WITH POWER OF AI</div>
           </div>
           <div className={MemberEndUsercss.TextOverlay2}>
              <div className={MemberEndUsercss.TextOverlayPara}>
                 Today, we create innovative solutions to the challenges that
                 consumers face in both their everyday lives and events.
              </div>
           </div>
           
        </div>
        <div className={MemberEndUsercss.RightHalf}> 
            <div className={MemberEndUsercss.Welcome}>
            <img src={SoilTech}  className={MemberEndUsercss.SoilTechLogo}/>  
            </div>
            <div className={MemberEndUsercss.WelcomeText}>Welcome to SoilTech</div>
            <div className={MemberEndUsercss.Welcomepara}>A transformative initiative by AIVOT that combines artificial intelligence with advanced agricultural practices to revolutionize soil management and farming.</div>
            <div className={MemberEndUsercss.Welcomepara2}>Designed to cater to both experts and farmers, Soil Tech offers AI-driven soil analysis, nutrient management recommendations, and actionable insights to optimize soil health and crop yields. </div>
            <div className={MemberEndUsercss.ButtonClass}>
                <button className={MemberEndUsercss.MemberButton}onClick={handleNavigate1} > Soil Tech  Member</button>
                <button className={MemberEndUsercss.EndUserButton}onClick={handleNavigate2} >EndUser</button>
            </div>

           
        </div>

    </div>
    
  )
}

export default MemberEndUser;