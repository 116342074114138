import React, { useState, useEffect } from "react";
import "./DHeader.css";
import farmer from "../../assets/image/farmer_image.png";
import { FaBars, FaBell } from "react-icons/fa"; // Notification icon
import Dropdown from "../../assets/icons/dropdown.svg";
import Sidebar from "../Sidebar/Sidebar";
import Hambager_out from "../../assets/icons/hambager_out.png";
import { useNavigate, useLocation } from "react-router-dom";
const dummyFarms = [
  { name: "Farm1", id: "abc458" },
  { name: "Farm2", id: "cbz765" },
  { name: "Farm3", id: "ktm928" },
  { name: "Farm4", id: "r15abz" },
  { name: "Farm5", id: "xcd130" },
];


const EnduserDHeader = ({ isMobile }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [language, setLanguage] = useState("English");
  const [selectedLanguage, setSelectedLanguage] = useState("Language");
  const [farmOpen, setFarmOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  const location = useLocation();
  // Dummy farm data
  const [selectedFarm, setSelectedFarm] = useState(dummyFarms[0].name);
  const currentId = localStorage.getItem("FarmId");
  const setid = currentId ? currentId : dummyFarms[0].id;
  const [selectedFarmId, setSelectedFarmId] = useState(setid);
  const savedImage = localStorage.getItem("profileImage");
  useEffect(() => {
    // This effect runs whenever selectedFarmId changes
    if (selectedFarmId) {
      localStorage.setItem("FarmId", selectedFarmId);
      window.dispatchEvent(new Event("storageChange"));
      setSelectedFarm(dummyFarms[selectedFarmId]);
    }
  }, [selectedFarmId]);

  useEffect(() => {
    const fetchFormId = () => {
      const id = localStorage.getItem("FarmId");
      setSelectedFarmId(id);
    };

    window.addEventListener("formIdFromAddFarm", fetchFormId);
    return () => {
      window.removeEventListener("formIdFromAddFarm", fetchFormId);
    };
  }, []);

  // Handle clicking outside to close the dropdown

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const handleImageClick = () => {
    navigate("/editprofile");
  };
  const handleFarmChange = (event) => {
    const selectedId = event.target.value; // Get the selected farm ID
    setSelectedFarmId(selectedId);

    const Farm = dummyFarms.find((farm) => farm.id === selectedId);
    if (Farm) {
      setSelectedFarm(Farm.name); // Set crossPending based on the selected farm's name
    }

    // Update the selected farm ID
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".dropdownButton") &&
        !event.target.closest(".dropdownMenu")
      ) {
        setLanguage(false);
        setFarmOpen(false);
        setNotificationOpen(false);
        setProfileOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
   
  }, []);



  if (isMobile) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <header className="header">
      

          {/* Sidebar for mobile */}
          {isMobileMenuOpen && (
            <Sidebar
              isMobileView={isMobile}
              onClose={() => setMobileMenuOpen(false)} // Prop to handle sidebar close
            />
          )}

         
        </header>
       
        <div className="welcome-section">
          <h1 className="welcome-text">Welcome {username}</h1>
          <p className="subtext">
            Take a soil test, view reports, and get AI-powered crop suggestions
            tailored to your farm.
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <header className="header">
        {/* Desktop View */}
        <div className="welcome-section">
          <h1 className="welcome-text">Welcome {username}</h1>
          <p className="subtext">
            Take a soil test, view reports, and get AI-powered crop suggestions
            tailored to your farm.
          </p>
        </div>

    
      </header>
    );
  }
};

export default EnduserDHeader;
